
  import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {wechatLoginHttp} from "~/api/operation";
  // import { Button, Input,InputNumber, Select, Upload, Notification, Message, MessageBox } from 'element-ui'
  import {loginBySmCodeHttp, getUserInfoHttp} from "~/api/user";
  import { sendSmsCodeHttp } from "~/api/comm";
  @Component({
    name:"loginMessageInput",
    components: {
      wechatLogin: require('~/components/comm/djhModal/wechatLogin.vue').default,
    },
  })
  export default class loginMessageInput extends Vue {
    public setCookie:any
    public getCookie:any
    public eventNotifyTost:any
    public eventMsgTost:any
    public errorToastFn:any//接口异常抛出
    public mobileRegular:any//手机号正则
    public openUrl:any
    public checkForm:any
    private phoneNum:string = ''
    private code:string = ''
    private inviteCode:string = ''
    private msgTxt:any = {
      phoneNum:'手机号为空',
      code:'验证码为空',
    }
    private selected:number = 0
    private countdown:number = 0
    private getMessageText:string = '发送验证码'
    private timer:any
    private wechatShow:boolean = false
    private loading:boolean = true
    private wechatValue:any = { url:'', code:''}
    @Emit('bindSend') send(msg: boolean){}; // send 处理给父组件传值的逻辑
    mounted(){
    }
    @Watch("phoneNum")
    getPhoneNum(newVal: any) {
      if (newVal == '') {
        this.msgTxt['phoneNum'] = '手机号为空'
      } else if (!this.mobileRegular.test(newVal)) {
        this.msgTxt['phoneNum'] = '手机号格式错误'
      } else {
        this.msgTxt['phoneNum'] = ''
      }
      this.phoneNum=newVal
    }
    @Watch("code")
    getCode(newVal: any) {
      if (newVal == '') {
        this.msgTxt['code'] = '验证码为空'
      } else {
        this.msgTxt['code'] = ''
      }
      this.code=newVal
    }
    //子组件控制显示
    @Emit()
    propIsShow(v:boolean){
      this.send(v)
    }
    //关闭定时任务
    @Emit()
    closeLoginModal(msg: boolean){
      this.wechatShow=msg
      clearInterval(this.timer)
    }
    @Emit()
    async mobileLogin(){ //登录
      for (let k in this.msgTxt) {
        if (this.msgTxt[k] != '') {
          this.eventNotifyTost('提示', this.msgTxt[k], 'warning')
          return
        }
      }
      try {
        let inviteCode:string=this.inviteCode
        if (!!this.inviteCode&&this.inviteCode.indexOf(":")==-1){
          inviteCode=window.location.host+":"+this.inviteCode
        }
        let {data}=await loginBySmCodeHttp({method:'POST',data:{phoneNum:this.phoneNum,code:this.code,inviteCode:inviteCode}})
        if (this.errorToastFn(data)) {
          this.setCookie('token', data.data.token)
          try { //登录成功后拉取用户信息
            let userData=await getUserInfoHttp()
            if (this.errorToastFn(userData.data)) {
              localStorage.setItem("token", data.data.token)
              this.setCookie("nickName", userData.data.data.nickName)
              this.setCookie("userId", userData.data.data.userId)
              this.setCookie("actor", userData.data.data.actor)
              this.setCookie("phoneNum", userData.data.data.phoneNum)
              this.eventMsgTost('登录成功','success')
              this.openUrl(this.getCookie('beforePath'))
            }
          } catch (e) {
            this.eventNotifyTost('提示', '网络超时', 'warning')
          }
        }
      } catch (e) {
        this.eventNotifyTost('提示', '网络超时', 'warning')
      }
    }
    @Emit()
    //发送手机验证码
    sendSmsCode() {
      let info:Array<any>=[
        {key:"phoneNum",value:this.phoneNum,tip:this.msgTxt['phoneNum']},
      ]
      if (this.checkForm(info)) { //表单验证
        if (this.countdown > 0) {
          return
        }
        let vm = this;
        sendSmsCodeHttp({ method: 'POST', data: { 'phoneNum': this.phoneNum } }).then(res => { //发送手机验证码
          if (this.errorToastFn(res.data)) {
            this.eventMsgTost('发送成功','success')
            //开启倒计时
            vm.countdown = 60;
            settime();
            function settime() {
              if (vm.countdown == 0) {
                vm.getMessageText = "获取验证码";
                return;
              } else {
                vm.getMessageText = "重新发送(" + vm.countdown + ")"
                vm.countdown--;
              }
              setTimeout(function () {
                settime()
              }, 1000)
            }
          }
        }).catch(error => {
          this.eventNotifyTost('提示', '网络超时', 'warning')
        })
      }
    }
    @Emit()
    async thirdLogin(type:string){ //第三方登录
      if (type=='qq') {
        window.open(`https://graph.qq.com/oauth2.0/show?which=Login&display=pc&response_type=code&client_id=101552466&redirect_uri=http://trxquy.natappfree.cc/api/user/qqLogin&state=0`,'_self')
      } else {
        try {
          this.wechatShow=true
          let {data} = await wechatLoginHttp({method:'POST'})
          setTimeout(()=>{
            this.loading=false
          },400)
          this.wechatValue=data
          try {
            this.timer = setInterval(async () => {
              let { data } = await wechatLoginHttp({ method: 'GET', id:this.wechatValue.code+'u' })
              console.log(data,'==========data===========')
              if (data && data.token) {
                clearInterval(this.timer)
                this.setCookie('token', data.token)
                try {
                  let userData=await getUserInfoHttp()
                  if (this.errorToastFn(userData.data)) {
                    this.setCookie("username", userData.data.username)
                    this.setCookie("userId", userData.data.data.userId)
                    this.setCookie("actor", userData.data.data.actor)
                    this.setCookie("phoneNum", userData.data.data.phoneNum)
                  }
                  window.open('/', '_self')
                } catch (e) {
                  this.eventNotifyTost('提示', '网络超时', 'warning')
                }
              }
            }, 1500)
          }catch (error) {
            this.errorToastFn(error)
          }
        }catch (error) {
          this.errorToastFn(error)
        }
      }

    }

  }
