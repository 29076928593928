
  import { Component, Vue, Emit, Prop } from '~/node_modules/vue-property-decorator'
  @Component({
    name:"loginHead",
    components: {
      // newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class loginHead extends Vue {
    @Prop(String) title?: '用户登陆' // 默认名称
    @Prop(String) snAndIsMyGoods_?: any
    private openUrl:any
    private webSiteUrl:string=''
    mounted(){
      
      let webUrl:string=''
      if(window.location.origin) {
        webUrl = window.location.origin
      } else {
        webUrl = window.location.protocol + '//' + window.location.host
      }
      this.webSiteUrl=webUrl
    }
  }
