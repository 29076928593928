
  import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {wechatLoginHttp} from "~/api/operation";
  import {getUserInfoHttp,loginHttp} from "~/api/user";
  @Component({
    name:"loginInput",
    components: {
      wechatLogin: require('~/components/comm/djhModal/wechatLogin.vue').default,
    },
  })
  export default class loginInput extends Vue {
    public setCookie:any //设置cookie
    public getCookie:any //获取cookie
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any //接口异常抛出
    public checkForm:any
    public openUrl:any
    private selected:number = 0
    private timer:any
    private phoneNum:string = '' //用户名/手机号
    private password:string = '' //密码
    private remember:boolean = false //是否记住密码
    private msgTxt:any = {
      phoneNum:'用户名/手机号为空',
      password:'密码为空',
    }
    private wechatShow:boolean = false
    private loading:boolean = true
    private wechatValue:any = { url:'', code:''}
    @Emit('bindSend') send(msg: boolean){}; // send 处理给父组件传值的逻辑
    mounted(){
      if (this.getCookie("accountInfo")) {//记住密码
        this.autoGetUserInfo()
      }
    }
    @Watch("phoneNum")
    getPhoneNum(newVal: any) {
      if (newVal == '') {
        this.msgTxt['phoneNum'] = '用户名/手机号为空'
      } else {
        this.msgTxt['phoneNum'] = ''
      }
      this.phoneNum=newVal
    }
    @Watch("password")
    getPassword(newVal: any) {
      if (newVal == '') {
        this.msgTxt['password'] = '密码为空'
      } else {
        this.msgTxt['password'] = ''
      }
      this.password=newVal
    }
    @Emit()
    autoGetUserInfo() {
      let accountInfo = this.getCookie('accountInfo')
      let userInfoArr = accountInfo.split("&")
      this.phoneNum = userInfoArr[0]
      this.password = userInfoArr[1]
      this.remember=true
    }
    //子组件控制显示
    @Emit()
    propIsShow(v:boolean){
      this.send(v)
    }
    //关闭定时任务
    @Emit()
    closeLoginModal(msg: boolean){
      this.wechatShow=msg
      clearInterval(this.timer)
    }
    @Emit()
    async loginFn(){
      let info:Array<any>=[
        {key:"phoneNum",value:this.phoneNum,tip:this.msgTxt['phoneNum']},
        {key:"password",value:this.password,tip:this.msgTxt['password']},
      ]
      if (this.checkForm(info)) { //表单验证
        try { // 登录接口
          let {data}=await loginHttp({method:'POST',data:{phoneNum: this.phoneNum,password: this.password}})
          if (this.errorToastFn(data)) {
            this.setCookie('token', data.data.token)
            try { //登录成功后拉取用户信息
              let userData=await getUserInfoHttp()
              if (this.errorToastFn(userData.data)) {
                localStorage.setItem("token", data.data.token)
                this.setCookie("nickName", userData.data.data.nickName)
                this.setCookie("userId", userData.data.data.userId)
                this.setCookie("actor", userData.data.data.actor)
                this.setCookie("phoneNum", userData.data.data.phoneNum)
                if (this.remember) {
                  this.setCookie("accountInfo", `${this.phoneNum}&${this.password}`)
                }
                this.eventMsgTost('登录成功','success')
                this.openUrl(this.getCookie('beforePath'))
              }
            } catch (e) {
              this.eventNotifyTost('提示', '网络超时', 'warning')
            }
          }
        } catch (error) {
          this.eventNotifyTost('提示', '网络超时', 'warning')
        }
      }

    }
    @Emit()
    async thirdLogin(type:string){
      if (type=='qq') {

      } else {
        try {
          this.wechatShow=true
          let {data} = await wechatLoginHttp({method:'POST'})
          setTimeout(()=>{
            this.loading=false
          },400)
          this.wechatValue=data
          try {
            this.timer = setInterval(async () => {
              let { data } = await wechatLoginHttp({ method: 'GET', id:this.wechatValue.code+'u' })
              console.log(data,'==========data===========')
              if (data && data.token) {
                clearInterval(this.timer)
                this.setCookie('token', data.token)
                try {
                  let userData=await getUserInfoHttp()
                  if (this.errorToastFn(userData.data)) {
                    this.setCookie("nickName", userData.data.data.nickName)
                    this.setCookie("userId", userData.data.data.userId)
                    this.setCookie("actor", userData.data.data.actor)
                    this.setCookie("phoneNum", userData.data.data.phoneNum)
                  }
                  window.open('/', '_self')
                } catch (e) {
                  this.eventNotifyTost('提示', '网络超时', 'warning')
                }
              }
            }, 1500)
          }catch (error) {
            this.errorToastFn(error)
          }
        }catch (error) {
          this.errorToastFn(error)
        }
      }

    }

  }
