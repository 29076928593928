
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import Cookie from "~/utils/cookie/cookie.js";
import { getNewsInfoHttp, sendSmsCodeHttp } from "~/api/comm";

import {
  getUserInfoHttp,
  loginHttp,
  loginBySmCodeHttp,
  forgetUserPassWordHttp,
  registerHttp,
  baiduPromotion,
} from "~/api/user";
import djhNav8 from "~/components/comm/djhNav/djhNav8.vue";
Component.registerHooks(["beforeRouteEnter"]);
@Component({
  name: "login",
  layout: function (context) {
    return "loginModal";
  },
  components: {
    LoginHead: require("~/components/comm/logoSearch/loginHead.vue").default,
    loginBackground: require("~/components/comm/login/loginBackground.vue")
      .default,
    loginInput: require("~/components/comm/login/loginInput.vue").default,
    loginMessageInput: require("~/components/comm/login/loginMessageInput.vue")
      .default,
    footerService4: require("~/components/comm/djhFooter/footerService4.vue")
      .default,
    djhNav8: require("~/components/comm/djhNav/djhNav8.vue").default,
  },
  beforeRouteEnter: (to: any, from: any, next: any) => {
    if (
      from.path != "/register" &&
      from.path != "/login" &&
      from.path != "/forgetPassword" &&
      from.path != "/mobileLogin"
    ) {
      Cookie.setCookiesInClient("beforePath", from.fullPath);
    }
    next();
  },
})
export default class login extends Vue {
  public setCookie: any; //设置cookie
  public getCookie: any; //获取cookie
  public errorToastFn: any;
  public eventMsgTost: any;
  public eventNotifyTost: any; //接口异常抛出
  public checkForm: any;
  public openUrl: any;
  private timer: any;
  public value1: any = "";
  public mobileRegular: any; //手机号正则
  private isLogined: Boolean = false; // 是否已登录
  private isShow: Boolean = true; // 是否显示手机号登录
  private isPassWordLogin: boolean = true; //当前tab状态是密码登陆还是短信登陆
  private isShowPassword: boolean = false; //是否显示密码
  private isShowModalPassword1: boolean = false; //是否显示弹窗表单中的密码
  private isShowModalPassword2: boolean = false; //是否显示弹窗表单中的确认密码
  private phoneNum: string = ""; //用户名/手机号
  private phoneNum1: string = ""; //短信登陆专用手机号
  private password: string = ""; //密码
  private confirm: boolean = false; // 是否同意用户隐私政策
  private isXieyi: boolean = false; //是否显示协议
  private isXieyi_Registe: boolean = false; // 注册时是否显示协议
  private xieyi: any = {}; //协议
  private code: string = ""; //短信验证码
  private inviteCode: string = ""; //邀请码
  public showClose: boolean = true;
  private openModalForm: any = {
    phoneNum: "",
    code: "",
    password: "",
    confirmPassword: "",
    inviteCode: "",
    confirm: false,
  }; //弹窗表单中的数据
  private isShowModal: boolean = false; // 是否显示弹窗
  private isShowModalUser: boolean = false; // 用户协议是否显示弹窗
  private isRegister: boolean = false; // 当前弹窗是注册还是忘记密码
  private sendCodeText: string = "获取验证码";
  private countdown: number = 0;
  private msgTxt: any = {
    // 账号密码登陆提示信息
    phoneNum: "用户名/手机号为空",
    password: "密码为空",
    code: "验证码为空",
    confirm: "请先阅读用户协议，并同意后再进行登录或注册",
  };
  private msgTxt1: any = {
    // 短信登陆提示信息
    phoneNum: "手机号为空",
    code: "验证码为空",
    confirm: false,
  };

  public wechatScanCodeDialogVisible: boolean = false;

  mounted() {
    // this.getLogin()   浏览器禁止重定向

    console.log(
      this.$store.state.web.distributorsObject.customerBlock,
      "========customerBlock=========="
    );
  }
  @Watch("phoneNum")
  getPhoneNum(newVal: any) {
    if (newVal == "") {
      this.msgTxt["phoneNum"] = "手机号为空";
    } else if (!this.mobileRegular.test(newVal)) {
      this.msgTxt["phoneNum"] = "手机号格式错误";
    } else {
      this.msgTxt["phoneNum"] = "";
    }
    this.phoneNum = newVal;
  }

  @Watch("confirm")
  getConfirm(newVal: any) {
    if (!newVal) {
      this.msgTxt["confirm"] = "请先阅读用户协议，并同意后在进行登录";
    }
    this.confirm = newVal;
  }
  @Watch("password")
  getPassword(newVal: any) {
    if (newVal == "") {
      this.msgTxt["password"] = "密码为空";
    } else {
      this.msgTxt["password"] = "";
    }
    this.password = newVal;
  }
  @Watch("code")
  getCode(newVal: any) {
    if (newVal == "") {
      this.msgTxt["code"] = "验证码为空";
    } else {
      this.msgTxt["code"] = "";
    }
    this.code = newVal;
  }
  @Watch("isShowModal")
  getModalStatus(newVal: any) {
    let app: any = document.getElementById("app");
    if (newVal) {
      console.log(app, "sfhknk");
      app.style.overflow = "hidden";
      app.style.height = "100vh";
    } else {
      app.style.overflow = "auto";
      app.style.height = "auto";
    }
  }
  @Watch("isShowModalUser")
  getModalUserStatus(newVal: any) {
    let app: any = document.getElementById("app");
    if (newVal) {
      console.log(app, "sfhknk");
      app.style.overflow = "hidden";
      app.style.height = "100vh";
    } else {
      app.style.overflow = "auto";
      app.style.height = "auto";
    }
  }

  @Emit() //同意扫码登录页面
  handleAgreeWechatScanCodeLogin() {
    //同意跳转到微信扫码页面
    this.handleWeChatScanCodeLogin();
    this.wechatScanCodeDialogVisible = false;
  }

  //关闭微信扫码登录页面
  @Emit()
  handleCloseWechatScanCodeDialog() {
    this.wechatScanCodeDialogVisible = false;
  }

  //打开微信扫码登录页面
  @Emit()
  handleWeChatScanCodeLogin() {
    // this.wechatScanCodeDialogVisible = true
    window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=wx8883631a434194d5&state=F90CBE4D71CA2750570C92E5BEC965E87ED8975F17015D835851AE7E9A1AA811A528F06E0CF4FF8F9EC6CA1EDBB88B8C&redirect_uri=https%3A%2F%2Fwww.hk1686.com&response_type=code&scope=snsapi_login#wechat_redirect`;
  }

  @Emit()
  //打开协议
  async openXieyi() {
    try {
      let { data } = await getNewsInfoHttp({ params: { newsId: 1000 } });
      if (this.errorToastFn(data)) {
        this.isXieyi = true;
        this.xieyi = data.data;

        console.log(
          data.data,
          "协议+++++++++++++++++++++++++++++++++++++++++++++++++++++++"
        );
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
    }
  }
  @Emit()
  //关闭协议
  cancelXieYi() {
    this.isXieyi = false;
  }
  @Emit()
  //关闭协议
  agreeNext() {
    this.isXieyi = false;
    this.confirm = true;
  }
  @Emit()
  //打开协议
  async openXieyi_Registe() {
    try {
      let { data } = await getNewsInfoHttp({ params: { newsId: 1000 } });
      if (this.errorToastFn(data)) {
        this.isXieyi_Registe = true;
        this.isXieyi_Registe = data.data;
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
    }
  }
  @Emit()
  //关闭协议
  cancelXieYi_Registe() {
    this.isXieyi_Registe = false;
  }
  @Emit()
  //关闭协议
  agreeNext_Registe() {
    this.isXieyi_Registe = false;
    this.confirm = true;
  }
  @Emit()
  // 接收子组件发送数据是 触发的事件
  propIsShow(msg: boolean) {
    this.isShow = msg;
  }
  changeLoginWay(way: any) {
    this.isPassWordLogin = way;
    this.isShowPassword = false;
    if (way) {
      // 选择密码登陆
      this.code = "";
    } else {
      this.password = "";
    }
  }
  showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  // 关闭弹框
  closeModal() {
    this.isShowModal = false;
    // 重置弹窗表单数据
    for (let key in this.openModalForm) {
      this.openModalForm[key] = "";
    }
  }

  // 关闭用戶协议弹框
  closeUserModal() {
    this.isShowModalUser = false;
  }
  // 开启弹窗
  showModal(str: any) {
    if (str == "register") {
      this.isRegister = true;
    } else {
      this.isRegister = false;
    }
    this.isShowModal = true;
  }

  showModalUser() {
    this.isShowModalUser = true;
  }
  // 弹窗中的显示密码显示的控制方法
  modalShowPassword1() {
    this.isShowModalPassword1 = !this.isShowModalPassword1;
  }
  // 弹窗中的显示确认密码显示的控制方法
  modalShowPassword2() {
    this.isShowModalPassword2 = !this.isShowModalPassword2;
  }
  // 发送验证码
  sendCode() {
    if (this.countdown > 0) {
      return;
    }
    let vm = this;
    let phoneNum = "";
    if (!this.isShowModal) {
      phoneNum = this.phoneNum1;
    } else {
      phoneNum = this.openModalForm.phoneNum;
    }

    sendSmsCodeHttp({ method: "POST", data: { phoneNum: phoneNum } })
      .then((res: any) => {
        //发送手机验证码
        if (this.errorToastFn(res.data)) {
          this.eventMsgTost("发送成功", "success");
          //开启倒计时
          vm.countdown = 60;
          settime();
          function settime() {
            if (vm.countdown == 0) {
              vm.sendCodeText = "获取验证码";
              return;
            } else {
              vm.sendCodeText = "重新发送(" + vm.countdown + ")";
              vm.countdown--;
            }
            setTimeout(function () {
              settime();
            }, 1000);
          }
        }
      })
      .catch((error) => {
        this.eventNotifyTost("提示", "网络超时", "warning");
      });
  }
  // 账号密码登陆
  async loginFn() {
    if (!this.isPassWordLogin) {
      // 选择的是密码登陆
      let info: Array<any> = [
        { key: "phoneNum", value: this.phoneNum, tip: this.msgTxt["phoneNum"] },
        { key: "password", value: this.password, tip: this.msgTxt["password"] },
        { key: "confirm", value: this.confirm, tip: this.msgTxt["confirm"] },
      ];
      if (this.checkForm(info)) {
        //表单验证
        let msg;
        try {
          // 登录接口
          if (!this.confirm) {
            this.$message.warning("请先阅读用户协议，并同意后再进行登录");
            return;
          }
          let result: any = await loginHttp({
            method: "POST",
            data: { phoneNum: this.phoneNum, password: this.password },
          });
          let data = result.data;
          msg = result.data.msg;

          console.log(data.data.user.loginStatus, "当前登录之后获取到的信息");
          if (this.errorToastFn(data)) {
            this.setCookie("token", data.data.token);
            try {
              //登录成功后拉取用户信息
              let userData: any = await getUserInfoHttp();
              console.log(userData.data.data.phoneNum, "1232222123123123");
              if (this.errorToastFn(userData.data)) {
                localStorage.setItem("token", data.data.token);
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(userData.data.data)
                );
                this.setCookie("nickName", userData.data.data.nickName);
                this.setCookie("userId", userData.data.data.userId);
                this.setCookie("actor", userData.data.data.actor);
                this.setCookie("phoneNum", userData.data.data.phoneNum);
                // console.log(this.getCookie('phoneNum'),'1232222123123123')
                this.eventMsgTost("登录成功", "success");
                let url = this.getCookie("beforePath");

                this.openUrl(url);
              }
            } catch (e) {
              this.eventNotifyTost("提示", msg, "warning");
            }
          }

          // if (data.data.user.loginStatus == 0) {   //绑定了也注册了  就直接跳转
          //   if (this.errorToastFn(data)) {
          //     this.setCookie("token", data.data.token);
          //     try {
          //       //登录成功后拉取用户信息
          //       let userData: any = await getUserInfoHttp();
          //       console.log(userData.data.data.phoneNum, "1232222123123123");
          //       if (this.errorToastFn(userData.data)) {
          //         localStorage.setItem("token", data.data.token);
          //         localStorage.setItem("userInfo", JSON.stringify(userData.data.data));
          //         this.setCookie("nickName", userData.data.data.nickName);
          //         this.setCookie("userId", userData.data.data.userId);
          //         this.setCookie("actor", userData.data.data.actor);
          //         this.setCookie("phoneNum", userData.data.data.phoneNum);
          //         // console.log(this.getCookie('phoneNum'),'1232222123123123')
          //         this.eventMsgTost("登录成功", "success");
          //         let url = this.getCookie("beforePath");

          //         this.openUrl(url);
          //       }
          //     } catch (e) {
          //       this.eventNotifyTost("提示", "网络超时", "warning");
          //     }
          //   }
          //   console.log("及登录了也注册了")
          // } else if (data.data.user.loginStatus == 1) { //提示   绑定微信  同意就去扫码   拒绝就直接登录
          //   if (this.errorToastFn(data)) {
          //     this.setCookie("token", data.data.token);
          //     try {
          //       //登录成功后拉取用户信息
          //       let userData: any = await getUserInfoHttp();
          //       console.log(userData.data.data.phoneNum, "1232222123123123");
          //       if (this.errorToastFn(userData.data)) {
          //         localStorage.setItem("token", data.data.token);
          //         localStorage.setItem("userInfo", JSON.stringify(userData.data.data));
          //         this.setCookie("nickName", userData.data.data.nickName);
          //         this.setCookie("userId", userData.data.data.userId);
          //         this.setCookie("actor", userData.data.data.actor);
          //         this.setCookie("phoneNum", userData.data.data.phoneNum);
          //         // console.log(this.getCookie('phoneNum'),'1232222123123123')
          //         // this.eventMsgTost("登录成功", "success");
          //         let url = this.getCookie("beforePath");

          //         this.wechatScanCodeDialogVisible = true
          //         // this.$message.error("登录了但是没有注册")
          //         // this.openUrl(url);
          //       }
          //     } catch (e) {
          //       this.eventNotifyTost("提示", "网络超时", "warning");
          //     }
          //   }

          //   console.log("登录了没有注册")
          // } else if (data.data.user.loginStatus == 2) {   //提示没有注册 请先使用验证码  注册账户
          //   this.$message.error('亲,请先注册账户再登录平台哦！')
          //   console.log("没登陆没注册")
          // }
        } catch (error) {
          // this.eventNotifyTost("提示", msg, "warning");
          this.$message({
            message: msg,
            type: "error",
          });
        }
      }
    } else {
      // 选择短信登陆
      // if(this.msgTxt['phoneNum'] != '') {
      //   this.$message.warning(this.msgTxt['phoneNum'])
      //   return
      // }

      let reg = /^1[3-9]\d{9}$/;
      if (this.phoneNum1 != "" && !reg.test(this.phoneNum1)) {
        return this.$message.warning("请输入正确手机号！");
      }
      if (this.msgTxt["code"] != "") {
        this.$message.warning(this.msgTxt["code"]);
        return;
      }

      if (!this.confirm) {
        this.$message.warning("请先阅读用户协议，并同意后再进行登录或注册");
        return;
      }
      let inviteCode: string;
      inviteCode = "www.hk1686.com:" + this.inviteCode;
      // console.log(inviteCode,73333890);
      let { data }: any = await loginBySmCodeHttp({
        method: "POST",
        data: {
          phoneNum: this.phoneNum1,
          code: this.code,
          inviteCode: inviteCode,
        },
      });
      //验证码登录 提示微信登录功能

      if (this.errorToastFn(data)) {
        this.setCookie("token", data.data.token);

        let userData: any = await getUserInfoHttp();
        console.log(userData, "1232222123123123");
        if (this.errorToastFn(userData.data)) {
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userInfo", JSON.stringify(userData.data.data));
          this.setCookie("nickName", userData.data.data.nickName);
          this.setCookie("userId", userData.data.data.userId);
          this.setCookie("actor", userData.data.data.actor);
          this.setCookie("phoneNum", userData.data.data.phoneNum);
          // console.log(this.getCookie('phoneNum'),'1232222123123123')
          this.eventMsgTost("登录成功", "success");
          let url = this.getCookie("beforePath");

          let currentTime: any = new Date();
          let specifiedTime: any = new Date(userData.data.data.createTime);
          let differenceInMilliseconds = currentTime - specifiedTime;
          let differenceInMinutes = differenceInMilliseconds / (1000 * 60);

          const params = window.sessionStorage.getItem("bd_vid");
          if (params && differenceInMinutes < 1) {
            let result = JSON.parse(params);
            // const baiduRequestUrl =
            //   'https://hk1686.com/' + `?bd_vid=${result}`;
            const baiduRequestUrl = window.location.href + `?bd_vid=${result}`;
            this.BAIDU(baiduRequestUrl);
            window.sessionStorage.removeItem("bd_vid");
            // if (url.includes("?")) {
            //   url = url + `&bd_vid=${result}`;
            // } else {
            //   url = url + `?bd_vid=${result}`;
            // }
          }
          this.openUrl(url);
        }
      }

      // if(data.data.user.loginStatus == 0){
      //   if (this.errorToastFn(data)) {
      //   this.setCookie("token", data.data.token);

      //   let userData: any = await getUserInfoHttp();
      //   console.log(userData, "1232222123123123");
      //   if (this.errorToastFn(userData.data)) {
      //     localStorage.setItem("token", data.data.token);
      //     localStorage.setItem("userInfo", JSON.stringify(userData.data.data));
      //     this.setCookie("nickName", userData.data.data.nickName);
      //     this.setCookie("userId", userData.data.data.userId);
      //     this.setCookie("actor", userData.data.data.actor);
      //     this.setCookie("phoneNum", userData.data.data.phoneNum);
      //     // console.log(this.getCookie('phoneNum'),'1232222123123123')
      //     this.eventMsgTost("登录成功", "success");
      //     let url = this.getCookie("beforePath");

      //     let currentTime: any = new Date();
      //     let specifiedTime: any = new Date(userData.data.data.createTime);
      //     let differenceInMilliseconds = currentTime - specifiedTime;
      //     let differenceInMinutes = differenceInMilliseconds / (1000 * 60);

      //     const params = window.sessionStorage.getItem("bd_vid");
      //     if (params && differenceInMinutes < 1) {
      //       let result = JSON.parse(params);
      //       // const baiduRequestUrl =
      //       //   'https://hk1686.com/' + `?bd_vid=${result}`;
      //       const baiduRequestUrl =
      //         window.location.href + `?bd_vid=${result}`;
      //       this.BAIDU(baiduRequestUrl);
      //       window.sessionStorage.removeItem("bd_vid");
      //       // if (url.includes("?")) {
      //       //   url = url + `&bd_vid=${result}`;
      //       // } else {
      //       //   url = url + `?bd_vid=${result}`;
      //       // }
      //     }
      //     this.openUrl(url);
      //   }
      // }
      // }else if(data.data.user.loginStatus == 1){
      //   if (this.errorToastFn(data)) {
      //   this.setCookie("token", data.data.token);

      //   let userData: any = await getUserInfoHttp();
      //   console.log(userData, "1232222123123123");
      //   if (this.errorToastFn(userData.data)) {
      //     localStorage.setItem("token", data.data.token);
      //     localStorage.setItem("userInfo", JSON.stringify(userData.data.data));
      //     this.setCookie("nickName", userData.data.data.nickName);
      //     this.setCookie("userId", userData.data.data.userId);
      //     this.setCookie("actor", userData.data.data.actor);
      //     this.setCookie("phoneNum", userData.data.data.phoneNum);
      //     // console.log(this.getCookie('phoneNum'),'1232222123123123')
      //     // this.eventMsgTost("登录成功", "success");
      //     let url = this.getCookie("beforePath");

      //     let currentTime: any = new Date();
      //     let specifiedTime: any = new Date(userData.data.data.createTime);
      //     let differenceInMilliseconds = currentTime - specifiedTime;
      //     let differenceInMinutes = differenceInMilliseconds / (1000 * 60);

      //     const params = window.sessionStorage.getItem("bd_vid");
      //     if (params && differenceInMinutes < 1) {
      //       let result = JSON.parse(params);
      //       // const baiduRequestUrl =
      //       //   'https://hk1686.com/' + `?bd_vid=${result}`;
      //       const baiduRequestUrl =
      //         window.location.href + `?bd_vid=${result}`;
      //       this.BAIDU(baiduRequestUrl);
      //       window.sessionStorage.removeItem("bd_vid");

      //       // if (url.includes("?")) {
      //       //   url = url + `&bd_vid=${result}`;
      //       // } else {
      //       //   url = url + `?bd_vid=${result}`;
      //       // }
      //     }
      //     this.wechatScanCodeDialogVisible = true
      //     // this.openUrl(url);
      //   }
      // }
      // }
    }
  }
  // 忘记密码和注册的弹窗
  async modalSubmit() {
    for (let key in this.openModalForm) {
      if (key == "phoneNum") {
        if (
          !this.openModalForm[key] ||
          !this.mobileRegular.test(this.openModalForm[key])
        ) {
          this.$message.warning("手机号格式错误");
          return;
        }
      }
      if (key == "code") {
        if (!this.openModalForm[key]) {
          this.$message.warning("手机验证码为空");
          return;
        }
      }
      if (key == "password") {
        if (!this.openModalForm[key]) {
          this.$message.warning("请填写密码");
          return;
        } else if (this.openModalForm[key].trim().length < 6) {
          this.$message.warning("请填写至少六位的密码长度");
          return;
        }
      }
      if (key == "confirmPassword") {
        if (this.openModalForm[key].length == 0) {
          this.$message.warning("请填写确认密码");
          return;
        } else if (this.openModalForm[key] != this.openModalForm["password"]) {
          this.$message.warning("两次输入的密码不同");
          return;
        }
      }
    }
    if (this.isRegister) {
      // 当前弹窗是注册功能
      try {
        //注册接口
        if (!this.confirm) {
          this.$message.warning("请先阅读用户协议，并同意后再进行注册");
          return;
        }
        let inviteCode: string;
        inviteCode = "www.hk1686.com:" + this.openModalForm.inviteCode;
        console.log(inviteCode, 1111222222);
        let { data } = await registerHttp({
          method: "POST",
          data: {
            phoneNum: this.openModalForm.phoneNum,
            password: this.openModalForm.password,
            code: this.openModalForm.code,
            confirmPassword: this.openModalForm.confirmPassword,
            inviteCode: inviteCode,
          },
        });
        if (this.errorToastFn(data)) {
          try {
            //注册成功后登录
            let loginData: any = await loginHttp({
              method: "POST",
              data: {
                phoneNum: this.openModalForm.phoneNum,
                password: this.openModalForm.password,
              },
            });
            if (this.errorToastFn(loginData.data)) {
              this.setCookie("token", loginData.data.data.token);
              try {
                //登录后拉取用户信息
                let userData: any = await getUserInfoHttp();
                if (this.errorToastFn(userData.data)) {
                  this.setCookie("nickName", userData.data.data.nickName);
                  this.setCookie("userId", userData.data.data.userId);
                  this.setCookie("actor", userData.data.data.actor);
                  this.setCookie("phoneNum", userData.data.data.phoneNum);
                  this.eventMsgTost("注册成功", "success");
                  this.openUrl(this.getCookie("beforePath"));
                }
              } catch (e) {
                this.eventNotifyTost("提示", "网络超时", "warning");
              }
            }
          } catch (e) {
            this.eventNotifyTost("提示", "网络超时", "warning");
          }
        }
      } catch (error) {
        this.eventNotifyTost("提示", "网络超时", "warning");
      }
    } else {
      // 当前弹窗是忘记密码
      try {
        let { data } = await forgetUserPassWordHttp({
          method: "POST",
          data: {
            phoneNum: this.openModalForm.phoneNum,
            code: this.openModalForm.code,
            password: this.openModalForm.password,
            confirmPassword: this.openModalForm.confirmPassword,
          },
        });
        if (this.errorToastFn(data)) {
          this.eventMsgTost("重置密码成功", "success");
          // this.openUrl('/login')
          this.isShowModal = false;
        }
      } catch (error) {
        this.eventNotifyTost("提示", "网络超时", "warning");
      }
    }
    this.closeModal();
  }

  async BAIDU(url: string) {
    let params = {
      //定义请求数据
      token:
        "IkDR0BTtWhlP5auVX9zXDYYlxmL8wD6u@4paOieQudyCEbEithtvF0Qrj9hLDqyGM",
      conversionTypes: [
        {
          logidUrl: url,
          newType: 25,
        },
      ],
    };

    let { data } = await baiduPromotion(params);
    console.log("百度data1", data);
  }

  goIndex() {
    this.openUrl(this.getCookie("beforePath"));
  }
}
