
  import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
  @Component({
    name:"loginBackground",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    },
  })
  export default class loginBackground extends Vue {
    public openUrl:any
    public getCookie:any //获取cookie

    mounted(){

    }
    goIndex(){
      this.openUrl(this.getCookie('beforePath'))
    }
  }
